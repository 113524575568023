@import '~antd/dist/antd.css';
@font-face {
  font-family: 'Prompt-Light';
  src: local('Prompt-Light'), url(./Assets/Prompt-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt-SemiBold';
  src: local('Prompt-SemiBold'),
    url(./Assets/Prompt-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: local('Prompt-Bold'), url(./Assets/Prompt-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt-Regular';
  src: local('Prompt-Regular'),
    url(./Assets/Prompt-Regular.ttf) format('truetype');
}

button,
input,
select,
textarea,
a,
p,
label,
span,
td,
tr,
div {
  font-family: Prompt-Light;
}

h1,
h2,
h3 {
  font-family: Prompt-SemiBold;
}

h4,
h5 {
  font-family: Prompt-Bold;
}

* {
  margin: 0;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  padding: 0;
}

:root {
  --main-color: linear-gradient(to top, #0d3e64, #682666, #e6004d);
  // --main-color-hover: #315035;
  // --color-dark: #1d2231;
  // --text-grey: #8390a2;
  // --btn-success: #5aa667;
  // --btn-refresh: #efeff1;
  // --btn-pending: #f7b224;
  // --btn-submit: #245894;
  // --btn-hover: #dedee2;
  // --btn-submit-hover: #396ba3;
  // --btn-pending-hover: #ebb342;
}
