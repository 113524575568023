.NavbarItems {
  background-image: linear-gradient(to top, #0d3e64, #682666, #e6004d);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  margin-left: 20px;
  justify-self: start;
  cursor: pointer;
  font-size: 2rem;
  color: #fff;
}

.navbar-logo h1 {
  padding: 1rem;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-image: linear-gradient(to top, #305979, #8d4d8b, #ec3c77);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.fa-bars {
  color: #fff;
  cursor: pointer;
}

.nav-links-mobile {
  display: none;
}

.fa-times {
  color: #fff;
  cursor: pointer;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
    height: 95px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 500px;
    height: fit-content;
    position: absolute;
    top: 90px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
  }

  .nav-menu.active{
     background-color: #305979;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    height: fit-content;
    z-index: 1000;
  }

  .nav-links{
      text-align: center;
      padding: 2rem;
      width: 90%;
      display: table;
  }

  .nav-links:hover{
    background-image: linear-gradient(to top, #456279, #9c749b, #da7999);;
    border-radius: 0;
}

    .navbar-logo{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0%,0%);
        max-width: 100px;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .carousel-image-container img{
        max-width: 900px !important;
        height: 200px !important;
        border-radius: 10px;
    }
    .carousel-image-container{
        border-radius: 20px;
    }
    
    .branner-container{
        margin: 1rem;
    }
}

.carousel-image-container img{
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-right: 1rem;
}

.carousel-image-container{
    border-radius: 20px;
}

.branner-container{
    margin: 1rem;
}

.bg{
    background: #f5f5f5;
    max-width: 100%;
    min-height: 110vh;
}